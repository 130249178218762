<template>
    <div class="social-chat-members">
        <StackRouterHeaderBar leftButton="close" />

        <main class="main">
            <h1 class="header f-medium m-b-40">
                참여 회원
                <span class="count">
                    <span class="c-blue-facebook">{{ currentLength }}</span>
                    <span class="c-text-lighter f-regular">/{{ event.member }}</span>
                </span>
            </h1>

            <ul class="members">
                <li class="member" v-for="member in members" :key="member.id" @click="onClickProfile(member)">
                    <img class="profile-img m-r-16" :src="member.photoUrl" />
                    <div>
                        <div class="name-badge">
                            <p class="name m-r-8">
                                {{ member.name }} <span class="f-regular">({{ gender(member) }})</span>
                            </p>
                            <div class="host-badge" v-if="member.isHost">
                                <img class="m-r-2" :src="require('@/assets/images/chat/social_host.svg')" />
                                호스트
                            </div>
                        </div>
                        <div class="age-region">
                            <p>{{ `${member.age}세, ${member.regionName}` }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </main>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar'

export default {
    name: 'SocialChatMembersPage',
    components: {
        StackRouterHeaderBar,
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        members: [],
    }),
    computed: {
        event() {
            return this.chat.event
        },
        currentLength() {
            return Object.keys(this.chat.users).length + 1
        },
        me() {
            return this.$store.getters.me
        },
    },
    methods: {
        initMembers() {
            this.members.push({
                id: this.me.id,
                name: this.me.name ? this.me.name : this.me.profile.nickname,
                age: this.$options.filters.asAge(this.me.profile.birthday),
                regionName: this.me.profile.region_name,
                photoUrl: this.me.photos[0].url,
                isHost: this.event.user_id === this.me.id,
                gender: this.me.gender,
            })

            const rest = Object.values(this.chat.users).map(user => {
                const isHost = user.id === this.event.user_id
                return {
                    id: user.id,
                    name: this.$nameOrNick(user) !== '---' ? this.$nameOrNick(user) : '탈퇴한 유저',
                    age: this.$options.filters.asAge(user.birthday),
                    regionName: user.region_name,
                    photoUrl: user.photo_url,
                    isHost,
                    gender: user.gender,
                }
            })

            this.members = this.members.concat(...rest).sort(a => {
                if (a.isHost) return -1
            })
        },
        gender({ gender }) {
            return gender === 0 ? '남' : '여'
        },
        onClickProfile({ id, photoUrl }) {
            // 소셜 프로필로 이동
            this.$stackRouter.push({
                name: 'SocialMeetProfilePage',
                props: {
                    userId: id,
                    photo: photoUrl,
                    from: 'social_meeting',
                },
            })
        },
    },
    mounted() {
        this.initMembers()
    },
}
</script>

<style lang="scss" scoped>
.social-chat-members {
    .main {
        padding: 0 16px;
    }

    .header {
        font-size: 24px;
        color: black;
        font-weight: 500;

        .count {
            font-size: 16px;
        }
    }

    .members {
        .member {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            .name-badge {
                display: flex;
                align-items: center;
            }

            .name {
                font-size: 16px;
                color: black;

                @include f-medium;
            }

            .host-badge {
                height: 22px;
                padding: 0 8px;
                border: solid 1px $blue-facebook;
                border-radius: 6px;
                font-size: 12px;
                color: $blue-facebook;

                img {
                    width: 12px;
                }

                @include center;
                @include f-bold;
            }

            .age-region {
                font-size: 13px;
                color: $grey-07;
            }
        }

        .profile-img {
            width: 48px;
            height: 48px;
            border-radius: 40%;
        }
    }
}
</style>
